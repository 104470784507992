import baja from 'images/pins/baja.png'
import commuteErin from 'images/pins/commute-erin.png'
import commuteJono from 'images/pins/commute-jono.png'
import commuteDaqri from 'images/pins/commute-daqri.png'
import dateLights from 'images/pins/date-lights.png'
import dateStairs from 'images/pins/date-stairs.png'
import datePier from 'images/pins/date-pier.png'
import dateChess from 'images/pins/date-chess.png'
import dateWheel from 'images/pins/date-wheel.png'
import mxStart from 'images/pins/mx-start.png'
import mxPizza from 'images/pins/mx-pizza.png'
import mxTj from 'images/pins/mx-tj.png'
import bbHome from 'images/pins/bb-home.png'
import bbCampsite from 'images/pins/bb-campsite.png'
import bbLost from 'images/pins/bb-lost.png'
import bbCold from 'images/pins/bb-cold.png'
import alcStart from 'images/pins/alc-start.png'
import alcEnd from 'images/pins/alc-end.png'
import cabotCar from 'images/pins/cabot-car.png'
import trnTalk from 'images/pins/trn-talk.png'
import trnSurprise from 'images/pins/trn-surprise.png'
import trnSwear from 'images/pins/trn-swear.png'
import cabot1 from 'images/pins/cabot-1.png'
import cabot2 from 'images/pins/cabot-2.png'
import cabot3 from 'images/pins/cabot-3.png'
import cheersStart from 'images/pins/cheers-start.png'
import cheersTodd from 'images/pins/cheers-todd.png'
import cheersEnd from 'images/pins/cheers-end.png'
import ft1start from 'images/pins/ft1-start.png'
import ft1drunk from 'images/pins/ft1-drunk.png'
import ft1cat from 'images/pins/ft1-cat.png'
import abqAirport from 'images/pins/abq-airport.png'
import lndnAirport from 'images/pins/lndn-airport.png'
import ft2monsoon from 'images/pins/ft2-monsoon.png'
import ft2fire from 'images/pins/ft2-fire.png'
import ft2hot from 'images/pins/ft2-hot.png'
import ft2end from 'images/pins/ft2-end.png'
import proposal from 'images/pins/proposal.png'

export default [ 
  {
    id: 'baja',
    description: `Baja Cantina`,
    image: baja, 
    coordinates: [-118.464302, 33.980918]
  },
  {
    id: 'commute-erin',
    description: `Her House`,
    image: commuteErin, 
    coordinates: [-118.346555, 34.068002] 
  },
  {
    id: 'commute-jono',
    description: `His House`,
    image: commuteJono, 
    coordinates: [-118.402526, 34.028643] 
  },
  {
    id: 'commute-daqri',
    description: `DAQRI`,
    image: commuteDaqri, 
    coordinates: [-118.259542, 34.054718] 
  },
  {
    id: 'date-lights',
    description: `LACMA Lights`,
    image: dateLights, 
    coordinates: [-118.359233, 34.063034] 
  },
  {
    id: 'date-stairs',
    description: `Culver Stairs`,
    image: dateStairs, 
    coordinates: [-118.381773, 34.017808] 
  },
  {
    id: 'date-pier',
    description: `Santa Monica Pier`,
    image: datePier, 
    coordinates: [-118.458056, 33.961550] 
  },
  {
    id: 'date-chess',
    description: `The Coffee Bean`,
    image: dateChess, 
    coordinates: [-118.480882, 33.999518] 
  },
  {
    id: 'date-wheel',
    description: `Santa Monica Ferris Wheel`,
    image: dateWheel, 
    coordinates: [-118.497859, 34.008305] 
  },
  // {
  //   id: 'sp-pedfork',
  //   image: spPedfork, 
  //   coordinates: [-118.638757, 34.157220] 
  // },
  // {
  //   id: 'sp-kiss',
  //   image: spKiss, 
  //   coordinates: [-118.415358, 34.028124] 
  // },
  // {
  //   id: 'sp-kareoke',
  //   image: spKareoke, 
  //   coordinates: [-118.344961, 34.060704] 
  // },
  // {
  //   id: 'sp-surf',
  //   image: spSurf, 
  //   coordinates: [-118.475582, 33.983623] 
  // },
  {
    id: 'mx-start',
    description: `His House`,
    image: mxStart, 
    coordinates: [-118.402526, 34.028643] 
  },
  {
    id: 'mx-pizza',
    description: `The New Yorker`,
    image: mxPizza, 
    coordinates: [-117.160726, 32.710940] 
  },
  {
    id: 'mx-tj',
    description: `Tiajuana`,
    image: mxTj, 
    coordinates: [-117.123539, 32.530205] 
  },
  {
    id: 'bb-home',
    description: `His Car`,
    image: bbHome, 
    coordinates: [-116.89321, 34.24902]
  },
  {
    id: 'bb-lost',
    description: `WTF`,
    image: bbLost, 
    coordinates: [-116.93159, 34.22008]
  },
  {
    id: 'bb-cold',
    description: `Brr!`,
    image: bbCold, 
    coordinates: [-116.92379, 34.22484]
  },
  {
    id: 'bb-campsite',
    description: `Zzz`,
    image: bbCampsite, 
    coordinates: [-116.94068, 34.22316]
  },
  {
    id: 'alc-start',
    description: `Cow Palace`,
    image: alcStart, 
    coordinates: [-122.419618, 37.706086]
  },
  {
    id: 'alc-end',
    description: `The Finish Line`,
    image: alcEnd, 
    coordinates: [-118.36115, 34.083313]
  },
  {
    id: 'trn-talk',
    description: `The Idea`,
    image: trnTalk, 
    coordinates: [-73.972381, 40.713640]
  },
  {
    id: 'trn-surprise',
    description: `The Suggestion`,
    image: trnSurprise, 
    coordinates: [-73.953481, 40.706816]
  },
  {
    id: 'trn-swear',
    description: `The Promise`,
    image: trnSwear, 
    coordinates: [-73.935271, 40.696980]
  },
  {
    id: 'cabot-car',
    description: `The Car`,
    image: cabotCar, 
    coordinates: [-60.818094, 46.081583]
  },
  {
    id: 'cabot-1',
    description: `Campsite 1`,
    image: cabot1, 
    coordinates: [-60.399438, 46.653664]
  },
  {
    id: 'cabot-2',
    description: `Campsite 2`,
    image: cabot2, 
    coordinates: [-60.460385, 46.886863]
  },
  {
    id: 'cabot-3',
    description: `Campsite 3`,
    image: cabot3, 
    coordinates: [-61.242475, 46.304907]
  },
  {
    id: 'cheers-start',
    description: `From Brooklyn With Love`,
    image: cheersStart, 
    coordinates: [-73.986587, 40.701424]
  },
  {
    id: 'cheers-todd',
    description: `Our Friend's House`,
    image: cheersTodd, 
    coordinates: [-72.682650, 41.284885]
  },
  {
    id: 'cheers-end',
    description: `Cheers Bar`,
    image: cheersEnd, 
    coordinates: [-71.071261, 42.355909]
  },
  {
    id: 'ft1-start',
    description: `From Brooklyn With Love`,
    image: ft1start, 
    coordinates: [-73.944107, 40.678313]
  },
  {
    id: 'ft1-cat',
    description: `RIP Feivel`,
    image: ft1cat, 
    coordinates: [-95.4360, 36.5268]
  },
  {
    id: 'ft1-drunk',
    description: `RIP Our Livers`,
    image: ft1drunk, 
    coordinates: [-85.737778, 38.253334]
  },
  {
    id: 'abq-airport',
    description: `Made it!`,
    image: abqAirport, 
    coordinates: [-106.612898, 35.043430]
  },
  {
    id: 'lndn-airport',
    description: `Shaken, not stirred!`,
    image: lndnAirport, 
    coordinates: [-71.071261, 42.355909]
  },
  {
    id: 'ft2-monsoon',
    description: `Do bikes conduct electricity?`,
    image: ft2monsoon, 
    coordinates: [-113.1451, 35.4825]
  },
  {
    id: 'ft2-fire',
    description: `The Museum Fire`,
    image: ft2fire, 
    coordinates: [-111.611443, 35.236048]
  },
  {
    id: 'ft2-hot',
    description: `Is it hot in here?`,
    image: ft2hot, 
    coordinates: [-115.745079, 34.568771]
  },
  {
    id: 'ft2-end',
    description: `The End!`,
    image: ft2end, 
    coordinates: [-118.499231, 34.007925] 
  },
  {
    id: 'proposal',
    description: `Put a ring on it!`,
    image: proposal, 
    coordinates: [-118.499231, 34.007925] 
  }
]
import React from 'react';

import ReactMarkdown from 'react-markdown'
import { isMobile } from "react-device-detect";

import './index.scss';

export const Credits = ({ setCreditsOpen }) => {

  const creditsContent = `
  Oh man, where to even begin. I'd like to thank the academy... Seriously though, I would not be where I am today without the following wonderful people and technology.

**People:**  
[My beautiful wife]() without whom none of this would need to exist.
[John and Marylin](https://www.youtube.com/watch?v=DuGppXvy8PU) meeting me at Baja for that first dinner. This is all your fault.  
[My brother](https://www.goodnight.rip/home) who orchestrated and recorded the entire proposal effort.
[Sam Oliver](https://www.instagram.com/sam.grad.oliver) for turning my sphaghetti code skills into ravioli.   
[Joe Iovino](https://jiovino.com/) for laughing at my terrible jokes.

**Technology:**  
[React](https://reactjs.org)  
[Mapbox GL JS](https://www.mapbox.com)  
[Google Maps](https://www.google.com/maps) 
[Strava](https://www.strava.com/)   
[Github](https://github.com)   
[Netlify](https://www.netlify.com)   
[Strava](https://www.strava.com/)    

**Typography:**  
[Canela](https://commercialtype.com/catalog/canela)   
[Spectral](https://www.productiontype.com/family/spectral)   
  `

  const clickOut = (e) => {
    if(e.target !== e.currentTarget) return;
    setCreditsOpen(false)
  }

  return (
    <div className='credits-overlay' onClick={(e)=> clickOut(e)}>
      <div className='credits'>
        <h3 className='section-header'>Special Thanks</h3>
        <span className='section-date'>Now, like right now.</span>
        <ReactMarkdown 
          className='section-text' 
          source={creditsContent} 
          renderers={{link: props => <a href={props.href} target={isMobile ? "_self" : "_blank"}>{props.children}</a>}}
          />
          <a className='next-section' href="https://www.ohyeahhowfar.com">
            Take me back to the beginning
          </a>
      </div>
    </div>
  )
}

import baja1 from 'images/photos/baja-1.jpg'
import baja2 from 'images/photos/baja-2.jpg'
import baja3 from 'images/photos/baja-3.jpg'

import com1 from 'images/photos/commute-1.jpg'
import com2 from 'images/photos/commute-2.jpg'
import com3 from 'images/photos/commute-3.jpg'

import date1 from 'images/photos/date-1.jpg'
import date2 from 'images/photos/date-2.jpg'
import date3 from 'images/photos/date-3.jpg'

import mx1 from 'images/photos/mx-1.jpg'
import mx2 from 'images/photos/mx-2.jpg'
import mx3 from 'images/photos/mx-3.jpg'

import bb1 from 'images/photos/bb-1.jpg'
import bb2 from 'images/photos/bb-2.jpg'
import bb3 from 'images/photos/bb-3.jpg'

import ily1 from 'images/photos/ily-1.jpg'
import ily2 from 'images/photos/ily-2.jpg'
import ily3 from 'images/photos/ily-3.jpg'

import alc1 from 'images/photos/alc-1.jpg'
import alc2 from 'images/photos/alc-2.jpg'
import alc3 from 'images/photos/alc-3.jpg'

import tony1 from 'images/photos/tony-1.jpg'
import tony2 from 'images/photos/tony-2.jpg'
import tony3 from 'images/photos/tony-3.jpg'

import trn1 from 'images/photos/trn-1.jpg'
import trn2 from 'images/photos/trn-2.jpg'
import trn3 from 'images/photos/trn-3.jpg'

import cbt1 from 'images/photos/cbt-1.jpg'
import cbt2 from 'images/photos/cbt-2.jpg'
import cbt3 from 'images/photos/cbt-3.jpg'

import bstn1 from 'images/photos/bstn-1.jpg'
import bstn2 from 'images/photos/bstn-2.jpg'
import bstn3 from 'images/photos/bstn-3.jpg'

import ft11 from 'images/photos/ft1-1.jpg'
import ft12 from 'images/photos/ft1-2.jpg'
import ft13 from 'images/photos/ft1-3.jpg'

import lndn1 from 'images/photos/lndn-1.jpg'
import lndn2 from 'images/photos/lndn-2.jpg'
import lndn3 from 'images/photos/lndn-3.jpg'

import ft21 from 'images/photos/ft2-1.jpg'
import ft22 from 'images/photos/ft2-2.jpg'
import ft23 from 'images/photos/ft2-3.jpg'

import pro1 from 'images/photos/pro-1.jpg'
import pro2 from 'images/photos/pro-2.jpg'
import pro3 from 'images/photos/pro-3.jpg'

import b2b1 from 'images/photos/b2b-1.jpg'
import b2b2 from 'images/photos/b2b-2.jpg'
import b2b3 from 'images/photos/b2b-3.jpg'

import wed1 from 'images/photos/wed-1.jpg'
import wed2 from 'images/photos/wed-2.jpg'
import wed3 from 'images/photos/wed-3.jpg'

export default {
  'he-biked-here': {
    content: {
      title: `"He biked here!"`,
      date:"September, 2016",
      body: 
`"Oh yeah? How far?" their waitress asked him, her eyes twinkling under the patio string lights of [Baja Cantina](http://www.bajacantina.com).

"Well, only 5 miles from Palms", he said.

"Psh, that's nothing, I bike 10 miles to and from here every day!" she countered.

They spent the rest of his meal exchanging challenges and eventually exchanged contact information (He asked for her [Strava](https://www.strava.com/features), she replied that she preferred Instagram)
  
He followed up on his offer by sending her [a cool five messages in a row](https://www.lovepanky.com/men/dating-women-tips-for-men/am-i-texting-too-much) that night.   

He was just looking for a friend.

She responded with her appreciation and then ghosted him.  

She wasn't looking for anything. 
      `
    },
    images: [
      baja1, baja2, baja3
    ],
    data: {
      bounds: [[-118.484598, 34.002162], [-118.448799, 33.959756]],
      options: {
        duration: 2000,
        bearing: 20,
        pitch: 50
      }
    },
    layers: ['baja']
  },
  'this-far': {
    content: {
      title: "This Far",
      date:"September, 2016",
      body: 
      `
They continued their separate commutes without speaking.

As they had mentioned, they each biked 10 miles to and from work, with a 1.5 mile overlap in their route. The probability that they'd passed each other was reasonably high for a city of 4 million people, but this isn't a [Hallmark movie](https://www.hallmarkchannel.com).

He designed icons and interfaces at [DAQRI](https://daqri.com) in Downtown.

She served tacos and tequila at [Baja Cantina](http://www.bajacantina.com) in Venice.

He couldn't stop thinking about her. 

She couldn't stop thinking about grad school.
      `
    },
    images: [
      com1, com2, com3
    ],
    data: {
      bounds: [[-118.485871, 34.091094], [-118.234792, 33.976735]],
      options: {
        duration: 2000,
        bearing: 0,
        pitch: 0
      }
    },
    layers: ['jono-commute', 'erin-commute', 'baja', 'commute-erin', 'commute-jono', 'commute-daqri']
  },

  'first-date': {
    content: {
      title: "Meet me at the lights",
      date:"October, 2016",
      body: 
`Despite him having crashed into her DMs [with all the grace and charm of the Kool Aid Man](https://www.youtube.com/watch?v=_fjEViOF4JE), she eventually decided to invite him on a ride. 

They met at the LACMA lights and took turns taking each other to their favorite places in the city. He took her to the top of the [Culver Stairs](https://modernhiker.com/hike/hiking-the-baldwin-hills-scenic-overlook/), she took him to the South Jetty. While talking, they realized that neither of them had been on the Ferris Wheel so, naturally, that's where they ended up.

They got along like old friends, making inside jokes and laughing at each other. He thought he had no chance with her, so he high-fived his way into the [friend-zone](https://en.wikipedia.org/wiki/Friend_zone). 

They had so much fun, they decided to do it again the following week and began hanging out regularly.
      `
    },
    images: [
      date1, date2, date3
    ],
    data: {
      bounds: [[-118.545481, 34.069393], [-118.329376, 33.930860]],
      options: {
        duration: 2000,
        bearing: 10,
        pitch: 40
      }
    },
    layers: ['firstDate', 'commute-erin', 'date-lights', 'date-stairs', 'date-pier', 'date-chess', 'date-wheel']
  },

  'tiajuana': {
    content: {
      title: "Take me to Tiajuana",
      date:"New Years Eve, 2016",
      body: 
`A couple months and a first kiss later, on a whim and a bit of a dare from her, they biked from his house to San Diego.

It rained twice, her backpack was incredibly heavy, and he forgot his wallet at home, so [things were going great](https://en.wikipedia.org/wiki/Sarcasm).

When they finally met their friends at [The New Yorker](http://food.theplainjane.com/2016/07/13/new-yorker-downtown-san-diego/) (only 3 hours late), they took a train to the border and a cab to Tijuana, where they spent New Years Eve singing, dancing, and drinking tequila on the beach.

Once they made it back to his house, he realized that he had forgotten [not just his wallet, but his keys as well](https://thoughtcatalog.com/veronica-melkonian/2016/11/9-ways-to-accept-the-fact-that-you-fucked-up/). Stuck in the cold, hungry, and with the super over two hours away, he punched out his window and they [broke into his house](https://www.lacriminaldefenseattorney.com/legal-dictionary/b/breaking-and-entering/). 

She slept over, but left before sunrise the next day.

She wasn't *that* into him.
      `
    },
    images: [
      mx1, mx2, mx3
    ],
    data: {
      bounds: [[-118.827726, 34.049595], [-116.529216, 32.343063]],
      options: {
        bearing: 20,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['mexico', 'toTJ', 'mx-start', 'mx-pizza', 'mx-tj']
  },

  'snow-problem': {
    content: {
      title: "Snow Problem",
      date:"January, 2017",
      body: 
`Feeling empowered after their trip, he decided it was his turn to invite her on a dangerous, poorly-planned adventure. He invited her up to Big Bear for an overnight [bikepacking](https://bikepacking.com/bikepacking-101/) trip.

[She declined.](https://www.beliefnet.com/love-family/relationships/9-signs-shes-just-not-that-into-you.aspx) 

He decided to go anyway. She had been sending him mixed signals and he decided a solo trip would be a great idea. 

His friends reluctantly dropped him off at the closed entrance to the trail. The snow was frozen, which made the short ride incredibly difficult (it didn't help that he got lost twice). 

He eventually made it to his campsite and set up his tent. After a few cold hours of failed attempts to fall asleep, he slid down the icy hill to his car and fell asleep in the back seat.

He awoke the following morning to a foot of snow.
      `
    },
    images: [
      bb1, bb2, bb3
    ],
    data: {
      bounds: [[-117, 34.29], [-116.8255, 34.18]],
      options: {
        bearing: 181,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['big-bear', 'bb-home', 'bb-lost', 'bb-cold', 'bb-campsite']
  },

  'official': {
    content: {
      title: "Strava or It Didn't Happen",
      date:"Valentines Day, 2017",
      body: 
`Emboldened by his accidental survival, he decided it was time to give her another shot.

On January 22, he "officially" asked her out.

She said no.

They were out at a bar and she made him wait until the next day when they had their heads on straight. 

On January 23, he "officially" asked her out again.

This time, [to his surprise](https://i.kym-cdn.com/photos/images/original/000/918/810/a22.jpg), she said yes.

Now, free to make grand romantic gestures, he went on a [big, stupid bike ride to announce his love to the world](https://www.strava.com/activities/866864212). For some reason, she thought it was cute, and they kept dating.
      `
    },
    images: [
      ily1, ily2, ily3
    ],
    data: {
      bounds: [[-118.42, 34.13], [-118.242, 34.01]],
      options: {
        bearing: -360,
        speed: 0.6,
        pitch: 23
      }
    },
    layers: ['love']
  },

  'love-bubble': {
    content: {
      title: "Into The Love Bubble",
      date:"June, 2017",
      body: `
On their first ride, she told him that she was volunteering at the [LA LGBT Center](https://lalgbtcenter.org/), and that she planned to go on [AIDS Lifecycle](https://www.aidslifecycle.org/), an annual fundraiser ride the foundation organizes. 

Naturally, before even thinking about [the implication of sharing a tent for a week](https://www.urbandictionary.com/define.php?term=bow%20chicka%20wow%20wow), he had invited himself along.

Now that they were "officially" dating, they joked that, if they didn't get sick of each other, they'd be together forever.

Seven days and 545 miles later, they weren't running for the hills, so it looked like they were stuck together.

...at least until she moved for grad school.
      `
    },
    images: [
      alc1, alc2, alc3
    ],
    data: {
      bounds: [[-122.9, 37.82], [-117.47, 32.76]],
      options: {
        bearing: 10,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['alc', 'alc-start', 'alc-end']
  },

  'moving-to-nyc': {
    content: {
      title: "Moving to NYC",
      date:"August, 2017",
      body: `
When he heard that she was applying to grad school, he began counting down the days he had left with her. He knew that, if she moved away, he would never see her again.

She was one of the last things keeping him in LA, so he resolved that, if she left, he would move back to Brooklyn.

Her dreams of grad school came true and she was accepted to [Hofstra's Creative Art Therapy Counseling](https://www.hofstra.edu/academics/colleges/health-human/macat/index.html) Masters program.

Since they were coincidentally headed in the same direction, he decided this was a great excuse for them to move together.

They put in their two weeks (his coworkers made him a cake), packed their things into a trailer (poorly), and set off East. 

He ditched her to catch a flight El Paso to start his new job at [Waverly Labs](https://www.waverlylabs.com/). She still hasn't fully forgiven him for this.

They moved into their first tiny walk-up apartment in Bushwick and immediately began [filling it with bikes](https://www.cyclist.co.uk/news/3501/n1-think-you-dont-need-another-bike-think-again). 
      `
    },
    images: [
      tony1, tony2, tony3
    ],
    data: {
      bounds: [[-126.809969, 49.355181], [-68.776764, 27.130281]],
      options: {
        bearing: 0,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['acrossTheUS', 'tony1', 'tony2']
  },

  'pinky-swear': {
    content: {
      title: "A Promise is a Promise",
      date:"June, 2018",
      body: `
One evening, on a subway ride back from a night out in the city, they had been discussing their bucket list.

They had already jumped out of a plane, [crashed a marathon](https://cyclingtips.com/2018/03/barely-controlled-chaos-the-life-and-death-of-the-wolfpack-marathon-crash-race/), and [almost won a Dirty Dancing competition](https://scontent.xx.fbcdn.net/v/t31.0-8/20117115_1366417286769600_7966635446868487600_o.jpg?_nc_cat=100&_nc_ohc=I3JVU_-Mq7YAX81c1fA&_nc_ht=scontent.xx&oh=38b9fe56bdc6c508b3ae954aeb5b5a1d&oe=5ED06878) [(almost)](https://scontent.xx.fbcdn.net/v/t31.0-8/20121501_1366418300102832_652147236665851624_o.jpg?_nc_cat=100&_nc_ohc=zg93XaCyHN4AX-Eey6P&_nc_ht=scontent.xx&oh=ab71fc713d79d93cdda2466c8d18eaa4&oe=5ECA4BA5), so they needed some fresh bad ideas to look forward to.

"I want to bike across the US before I'm 30," she told him.

"Then I guess we're doing it next summer after you graduate."

...

After an extended, palpable silence, they realized there was no escape. The idea was out, and there was no putting it back.

They made an unbreakable pinky-promise, kissed their thumbs and, just like that, [Feel Trip](https://www.feeltrip.us) was born.
      `
    },
    images: [
      trn1, trn2, trn3
    ],
    data: {
      bounds: [[-73.996560, 40.726129], [-73.889221, 40.680203]],
      options: {
        bearing: 30,
        pitch: 80
      }
    },
    layers: ['train', 'trn-talk', 'trn-surprise', 'trn-swear']
  },

  'training-ride-1': {
    content: {
      title: "The First Training Ride",
      date:"July, 2018",
      body: `
They had a year until their cross-country tour, which seemed like plenty of time to prepare.

She picked him up from work and they drove for 15 hours up to Baddeck, the southern tip of the [Cabot Trail]().

Their first self-planned bikepacking trip went surprisingly well (although it's fairly difficult to get lost riding in a circle).

They left their car and began their four-day, incredibly mountainous adventure around the cape.

Despite discovering they had apparently gone the wrong way, as the winds on the North shore blew strong and to the East, they had nailed the ride and were excited for the tour.

They felt ready.
      `
    },
    images: [
      cbt1, cbt2, cbt3
    ],
    data: {
      bounds: [[-61.794653, 47.080018], [-60.052545, 45.992950]],
      options: {
        bearing: 10,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['cabot', 'cabot-car', 'cabot-1', 'cabot-2', 'cabot-3', 'cabot-car']
  },

  'training-ride-2': {
    content: {
      title: "The Second Training Ride",
      date:"St Patrick's Day Weekend, 2019",
      body: `
With a few months left before their big ride and her final semester around the corner, they had one last chance to train.

Sure, it was early March, and temperatures were just above freezing, but they had made a promise, so on they went.

Halfway into the second day of the ride to Boston, she realized her ankle was sprained and had swelled up like a balloon. Rather than give up, she decided to tough it out.

That was when he was sure she could make it across the country, if not with training then on stubbornness alone.

A couple miles later, she nearly collapsed from pain. He put her bags on his bike and they finished the last hundred miles.

That was when she was sure he was the one.

They made it to Cheers Bar, immediately collapsed into their beers, and barely caught the last bus home.

They no longer felt ready.
      `
    },
    images: [
      bstn1, bstn2, bstn3
    ],
    data: {
      bounds: [[-74.85, 42.83], [-70.05, 40.856]],
      options: {
        bearing: 10,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['boston', 'cheers-start', 'cheers-todd', 'cheers-end']
  },


  'go-west-young-couple': {
    content: {
      title: "Go West, Young Couple",
      date:"May, 2019",
      body: `
[Feel Trip](https://www.feeltrip.us) began about as smoothly as you'd expect.

She awoke to a flat (the first of 28), almost missed the ferry, then got off a stop early, adding 5 miles to their 106 mile day. 

[It got better from there.](https://knowyourmeme.com/memes/the-narrator/)

Along the way, they got drunk and ate pickled eggs at [Taj's in Louisville](https://www.tajnulu.com/), heard a band play [Take Me Home, Country Roads](https://www.youtube.com/watch?v=1vrEljMfXYo) in West Virginia, and almost rescued a cat in Oklahoma.

The whole story is available on [Instagram](https://www.instagram.com/feeltrip.us/) but, for the sake of brevity, we're going to move on. Cool? Cool.

Despite losing a day in New Mexico after passing out from heat exhaustion due to getting lost on a private ranch, they made it to Albuqurque just in time for their flight.

Flight? What flight?
      `
    },
    images: [
      ft11, ft12, ft13
    ],
    data: {
      bounds: [[-70.614, 41.841], [-109.022, 34.742]],
      options: {
        bearing: 0,
        speed: 0.6,
        pitch: 20
      }
    },
    layers: ['feeltrip-1', 'ft1-start', 'ft1-cat', 'ft1-drunk', 'abq-airport']
  },


  'left-turn-at-albuqurque': {
    content: {
      title: "Left Turn At Albuqurque",
      date:"July, 2019",
      body: `
Never one to do something simply, she had decided to take a break in the middle of the ride to do a talk at the [British Assiociation of Art Therapists' conference in London] (https://www.baat.org/Assets/Docs/International%20conference/June%20Programs/Friday%20Early%20Afternoon%20Program.pdf). 

The incredible offer had come in the middle of planning the ride and, rather than giving up on their plan, they had decided to make it work (she would, after all, need to find a job when they returned).

They left their bikes at a shop in ABQ and, less than 24 hours later, were in the sky on their way to London. 

She spent her days at the conference, he worked remote from a [bike themed coffeeshop](https://www.lookmumnohands.com/) screening [the Tour de France](http://seriousstartups.com/2016/09/15/why-and-how-you-should-stay-on-brand-at-all-times/).

After a week that might've been 5 seconds, they were in the sky on their way back to the desert to finish the ride.
      `
    },
    images: [
      lndn1, lndn2, lndn3
    ],
    data: {
      bounds: [[-110.93, 54.645633], [9.5, 17.78]],
      options: {
        bearing: 10,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['feeltrip-1', 'london']
  },


  'just-deserts': {
    content: {
      title: "Just Deserts",
      date:"July, 2019",
      body: `
With the conference behind them and less than 1000 miles of highway shoulder ahead, they thought the worst was over.

They were wrong.

George Washington once said, "True friendship is a plant of slow growth, and must undergo and withstand the shocks of adversity, before it is entitled to the appellation."

After narrowly avoiding a [forest fire](https://ktar.com/story/2739085/forest-crew-accidentally-sparked-museum-fire-in-northern-arizona/), almost getting struck by lightening in a monsoon, and riding [through the desert in a 118° heatwave](https://www.wunderground.com/history/daily/us/ca/palm-springs/KPSP/date/2019-7-27), their friendship had been through its fair share of shocks of adversity. 

They were finally ready.

They reached the Santa Monica Pier at 1:23pm on August 1st and were immediately showered with love and champagne, and surrounded by friends, family and loved ones. 
      `
    },
    images: [
      ft21, ft22, ft23
    ],
    data: {
      bounds: [[-121.716, 36.72], [-103.877, 33.313]],
      options: {
        bearing: 0,
        speed: 0.6,
        pitch: 30
      }
    },
    layers: ['feeltrip-1', 'feeltrip-2', 'ft2-monsoon', 'ft2-fire', 'ft2-hot', 'ft2-end']
  },


  'lucky-penny': {
    content: {
      title: `And A Penny in Your Shoe`,
      date:"August, 2019",
      body: `
When they had finished celebrating and taking photos, they walked back to the fence to grab their bikes.

As she reached for her bike, she noticed a glint on the ground. She bent down and realized it was a [shiny lucky penny](https://www.youtube.com/watch?v=4F4qzPbcFiA). 

About half-way through picking it up, it dawned on her that this was oddly convenient. Why were all of her friends and family standing around? Why were they looking behind her?

She spun around, knowing full well what was happening. 

He was down on one knee with a ring in his hand.

She reached into her back pocket and pulled out her own ring, which she had been carrying all day. [It's 2020 after all.](https://slate.com/human-interest/2020/01/how-to-propose-to-man-as-woman.html)

They were both too excited to say anything, so we're still not quite sure whether they're actually engaged or not.

For the sake of the story we're going to pretend they are.
      `
    },
    images: [
      pro1, pro2, pro3
    ],
    data: {
      bounds: [[-118.510558, 34.012143], [-118.487018, 34.002677]],
      options: {
        bearing: 10,
        speed: 0.6,
        pitch: 40
      }
    },
    layers: ['feeltrip-2', 'proposal']
  },

  'we-biked-here': {
    content: {
      title: `"We Biked Here!"`,
      date:"August, 2019",
      body: `
Once the excitement was over and their families had taken a photo from every imaginable angle, the newly engaged couple rode their bikes down the final stretch boardwalk back to the place where it all began.

Their friends and family were already waiting for them at Baja Cantina, where they ate tacos, drank grande margaritas, and then snuck off for a night of Karaoke and [the Whaler](https://www.venicewhaler.com/).

"They biked here," their friends told the bartender.

"Oh Yeah? How Far?"
      `
    },
    images: [
      b2b1, b2b2, b2b3
    ],
    data: {
      bounds: [[-118.484598, 34.002162], [-118.448799, 33.959756]],
      options: {
        duration: 2000,
        bearing: 20,
        pitch: 50
      }
    },
    layers: ['feeltrip-2', 'proposal', 'back2baja', 'baja']
  },

  'roll-credits': {
    content: {
      title: "Roll Credits",
      date:"January, 2021",
      body: `
Now that we're all caught up, let's get down to brass tacks. 

They're getting married somewhere purposefully remote and difficult to get to. Then, when they're good and exhausted, they're coming back to New York to celebrate with all of you. 

If you enjoyed yourself, please check out the credits below.

Thank you for wasting precious minutes of your life reading about our little adventure! 

Hopefully it inspires you to go on one of your own.

💖
      `
    },
    images: [
      wed1, wed2, wed3
    ],
    data: {
      bounds: [[-156.755, 21.0855], [-155.9, 20.5]],
      options: {
        bearing: 0,
        speed: 0.6,
        pitch: 60
      }
    },
    layers: ['baja']
  },
};
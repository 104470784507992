import React, { useState } from 'react'
import {useSpring, animated, config} from 'react-spring'
import classNames from 'classnames'

import scroll from 'images/gesture-scroll.svg'
import keys from 'images/gesture-keys.svg'
import swipe from 'images/gesture-swipe.svg'
import heart from 'images/intro-heart.png'

import {
  BrowserView,
  MobileView
} from "react-device-detect";

import "./index.scss"

export const IntroModal = ({loaded, enterCallback}) => {
  const [enterClicked, setEnterClicked] = useState(false);

  const enterClass = classNames({
    'enter-button': true,
    'loaded': loaded
  })

  const props = useSpring({
    opacity: !enterClicked ? 1 : 0,
    config: config.default,
  })

  return (
    <React.Fragment>
        <animated.div className='intro-modal' style={{...props}}>
          <h1 className='intro-header'>Oh Yeah? How Far?</h1>
          <p className='intro-text'>The story of Jono and Erin</p>
          <img src={heart} className='intro-heart' alt='Heart'/>
          <BrowserView>
            <p className='intro-text'>Scroll or use the keyboard to navigate</p>
            <div className='gesture-container'>
              <img src={scroll} className='gesture-image' alt='Instructional gesture icon'/>
              <p className='gesture-text'>or</p>
              <img src={keys} className='gesture-image' alt='Instructional gesture icon'/>
            </div>
          </BrowserView>
          <MobileView>
            <p className='gesture-text'>Swipe left to navigate between sections</p>
            <img src={swipe} className='gesture-image' alt='Instructional gesture icon'/>
          </MobileView>
          <button className={enterClass} onClick={loaded ? () => {setEnterClicked(true); enterCallback()} : undefined}>{loaded ? 'Enter' : 'Loading'}</button>
        </animated.div>
    </React.Fragment>
  )
}
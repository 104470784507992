import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom'

import ReactGA from 'react-ga';

import { Home } from './pages/Home'
import { NoMatch } from './pages/NoMatch';

export default function App() {
// Setup Google Analytics
  useEffect(() => {
    ReactGA.initialize('UA-158571233-1');
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <div className="App">
      <Switch>
        <Route path='/:slug' component={Home}/>
        <Route exact path='/' component={Home}/>
        <Route component={NoMatch}/>
      </Switch>
    </div>
  );
}
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { isMobile } from "react-device-detect";

import chapters from 'maps/chapters.js'

import "./index.scss"

export const Section = ({ chapterName, onImageClick, isPortraitMobile, height, nextChapter, slug, setCreditsOpen }) => {

const chapter = chapters[chapterName]

const lastChapter = chapterName === 'roll-credits'

  return (
    <section id={chapterName} className={chapterName === slug ? 'active' : undefined}>
      <h3 className='section-header'>{chapter.content.title}</h3>
      <span className='section-date'>{chapter.content.date}</span>
      <ReactMarkdown 
        className='section-text' 
        source={chapter.content.body} 
        renderers={{link: props => <a href={props.href} target={isMobile ? "_self" : "_blank"}>{props.children}</a>}}
        />
      <div className='thumbs'>
        {chapter.images.map((image, i) =>
          <img src={image} className='thumb' key={i} alt='' onClick={() => onImageClick(i)} />
        )}
      </div>
      {(height > 850) && !isMobile && !lastChapter &&
        <div className='next-section' onClick={nextChapter}>
          scroll or use arrows ↓
        </div>
      }
      {isPortraitMobile && !lastChapter &&
        <div className='swipe-hint' onClick={nextChapter}>
          swipe left to continue ->
        </div>
      }
      {lastChapter &&
        <a className='next-section credits-link' onClick={() => setCreditsOpen(true)}>
          Show me the credits!
        </a>
      }
    </section>
  )
}
import React, { useEffect, useRef, useState } from 'react';

// Packages
import mapboxgl from 'mapbox-gl'
import ReactGA from 'react-ga';
import { useWindowSize, useScroll, useKey } from 'react-use';
import { useHistory } from 'react-router-dom'
import {
  isMobile,
  isBrowser, 
  isSafari
} from "react-device-detect";
import Lightbox from 'react-image-lightbox';

// Sections
import { IntroModal } from './IntroModal';
import { Section } from './Section'
import { Credits } from '../Credits';

// Data
import points from 'maps/points.js'
import layers from 'maps/layers.js'
import chapters from 'maps/chapters.js'

// Style
import 'mapbox-gl/src/css/mapbox-gl.css';
import 'react-image-lightbox/style.css';
import './index.scss'


export const Home = ({ match }) => {
    var chapterNames = Object.keys(chapters);
    // If the slug chapter exists, set the slug equal to that chapter, otherwise send the person to the homepage
    let slug = chapterNames.includes(match.params.slug) ? match.params.slug : undefined;
    let history = useHistory();
    // Map states and settings
    const [loaded, setLoaded] = useState(false);
    const [map, setMap] = useState(null);
    // Set up the map container reference
    const mapContainer = useRef(null);
    // Modal states
    const [introDestroyed, setIntroDestroyed] = useState(slug ? true : false);
    const [creditsOpen, setCreditsOpen] = useState(false);
    //Set the chapternames as an array
    const [activeChapterName, setActiveChapterName] = useState(slug ? slug : 'he-biked-here');
    const [chapterIndex, setChapterIndex] = useState(0);
    const [keyIndex, setKeyIndex] = useState(0);
    
    // Image states and settings
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    let images = chapters[activeChapterName].images;
    
    // Size and scroll refs
    const {width, height} = useWindowSize();
    const scrollRef = React.useRef(null);
    const {x, y} = useScroll(scrollRef);
    let isPortraitMobile = isMobile && height > width;
    let isScrolly = height < width && height < 820;

    const hoverRef = React.useRef(null)
    // const {docX, docY} = useMouse(mapContainer);
    const [mousePosition, setMousePosition] = useState({x:0, y:0});  
    const [hover, setHover] = useState(false);

    // Set a state with the active chapterName
    const nextChapter = () => {
      setChapterIndex(index => {if (index < chapterNames.length - 1) {setKeyIndex(index + 1); return index + 1} else {setKeyIndex(index); return index}})
    }

    const prevChapter = () => {
      setChapterIndex(index => {if (0 < index) {setKeyIndex(index - 1); return index - 1} else {setKeyIndex(index); return index}})
    }
    
    // Keyboard key hooks
    useKey('ArrowUp', prevChapter);
    useKey('ArrowDown', nextChapter);
    useKey('ArrowLeft', prevChapter);
    useKey('ArrowRight', nextChapter);

    // When the key is pressed, jump to the next chapter
    useEffect(() => {
      setActiveChapter(chapterNames[chapterIndex])
      scrollToElement(chapterNames[chapterIndex], true)
    }, [keyIndex])

    // *
    // *
    // MAP STUFF
    // *
    // *


    // Map initialization
    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiamZyZWU2NCIsImEiOiJjajZ3YWNuM3oxYWh0MzhvM3RxcDJlOXB5In0.1nebqO9VfnJmK4DpLWRFKg'

        const initializeMap = ({ setMap, mapContainer }) => {
            const map = new mapboxgl.Map({
              container: mapContainer.current,
              style: "mapbox://styles/jfree64/ck2tk6m665gc61cul6vzou7zq?optimize=true", // stylesheet location
              pitch: slug ? chapters[slug].data.options.pitch: 0,
              // bearing: chapters[slug].data.bearing, This breaks everything
              bounds: slug ? chapters[slug].data.bounds : [[-126.809969, 49.355181], [-68.776764, 27.130281]],
              keyboard: false
            });
            map.once("load", async () => {
              setMap(map);
              // This function maps through the list of images and imports and sets them all
              points.map(({ id, image, coordinates, description }) =>  {
                // This loads the image and makes sure it's correctly referenced
                map.loadImage(
                  image,
                  (error, image) => {
                    if (error) throw error;
                    map.addImage(id, image);
                  }
                );
              
                // This adds the image to the map
                map.addLayer({
                  'id': id,
                  'type': 'symbol',
                  'source': {
                    'type': 'geojson',
                    'data': {
                      'type': 'FeatureCollection',
                      'features': [
                        {
                          'type': 'Feature',
                          'properties': {
                            'description': description,
                          },
                          'geometry': {
                            'type': 'Point',
                            'coordinates': coordinates
                          }
                        }
                      ]
                    }
                  },
                  'layout': {
                    'icon-image': id,
                    'icon-size': 0.25,
                    'icon-allow-overlap': true
                  }
                });
                
                if (isBrowser) {
                  map.on('mouseenter', id, function (e) {
                    map.getCanvas().style.cursor = 'pointer';
                    setHover(true)
                    const content = e.features[0].properties.description
                    hoverRef.current.innerHTML = content;
                    hoverRef.current.style.display = `block`
                  });
           
                  map.on('mouseleave', id, function () {
                    map.getCanvas().style.cursor = '';
                    setHover(false)
                    hoverRef.current.style.display = `none`
                  });
                }

                map.setLayoutProperty(id, 'visibility', activeChapterContainsLayer(id) ? 'visible' : 'none')
              });

              // This iterates through the layers and adds them all below the last text layer before hiding them
              layers.map((layer) => {
                map.addLayer(layer, layer.type !== 'Arclayer' ? 'settlement-subdivision-label' : '')
                map.setLayoutProperty(layer.id, 'visibility', activeChapterContainsLayer(layer.id) ? 'visible' : 'none')
              });

              setActiveChapter(activeChapterName)
              setLoaded(true)
            })
        }

        // If the map isn't loaded, load it!
        if (!map) initializeMap({ setMap, mapContainer });
    }, [map]);

    // This sets the current chapter based on scroll position
    const setActiveChapter = (chapterName) => {
      // Return if it's the same every time after the first
      if (loaded && chapterName === activeChapterName) return;
      //if the map is loaded, fit the bounds in frame, then set the rest of the options
      if (map) {
        map.fitBounds(chapters[chapterName].data.bounds, chapters[chapterName].data.options)
        // Hacky way to not allow interaction during animation
        mapContainer.current.classList.add('notouch')
        map.once('moveend', () => mapContainer.current.classList.remove('notouch'))

        chapters[activeChapterName].layers.map((layer) => {map.setLayoutProperty(layer, 'visibility', 'none')})
        chapters[chapterName].layers.map((layer) => {map.setLayoutProperty(layer, 'visibility', 'visible')})
      }
      
      // before we change chapters, set the old active chapter to inactive and activate the new chapter
      document.getElementById(chapterName).setAttribute('class', 'active');
      // Make everything invisible (but only after the first time, where it's the same layer)
      loaded && document.getElementById(activeChapterName).setAttribute('class', '');

      introDestroyed && history.push(`/${chapterName}`)

      ReactGA.pageview(window.location.pathname);
      
      setActiveChapterName(chapterName);
    }
    
    const activeChapterContainsLayer = (id) => {

      if (slug && chapters[slug].layers.includes(id)) {return true} else {return false}
    }

    // Check to see where we are once a desktop user scrolls
    const getChapterIndex = () => {
      const index = isPortraitMobile ? Math.round(x / width) : Math.round(y / height)
      if (index === chapterIndex) return;
      setChapterIndex(index);
    }
    
    const getChapterIndexScroll = () => {
      for (var i = 0; i < chapterNames.length; i++) {
        var chapterName = chapterNames[i];
        if (isElementOnScreen(chapterName)) {
          setChapterIndex(i)
        break;
        }
      }
    }

    const  isElementOnScreen = (id) => {
      var element = document.getElementById(id);
      var bounds = element.getBoundingClientRect();
      return bounds.top < height && bounds.bottom > 0;
    }

    // Function called when a user on desktop scrolls vertically
    const onWheelHandler = () => {
      console.log('scroll event!')
      isScrolly ? getChapterIndexScroll() : getChapterIndex()
      setActiveChapter(chapterNames[chapterIndex])
    }


    const  scrollToElement = (id, smooth) => {
      var element = document.getElementById(id);
      var sectionTop = element.offsetTop
      const scrollBehavior = smooth ? 'smooth' : 'auto'
      if (isPortraitMobile) {
        scrollRef.current.scroll({left: width * chapterIndex, top:0, behavior: scrollBehavior})
      } else {
        scrollRef.current.scroll({left: 0, top: sectionTop, behavior: scrollBehavior})
      }
    }

     // Function called when a user on mobile swipes horizontally
     useEffect(() => {
      if (loaded && isPortraitMobile) {
        getChapterIndex() 
        setActiveChapter(chapterNames[chapterIndex])
      }
    }, [x])

     useEffect(() => {
      if (loaded && isMobile && isScrolly) {
        getChapterIndexScroll()
        setActiveChapter(chapterNames[chapterIndex])
      }
    }, [y])

      // Function called when a user on mobile swipes horizontally
      useEffect(() => {
      if (!isMobile && hover) {
        hoverRef.current.style.top = `${mousePosition.y}px`
        hoverRef.current.style.left = `${mousePosition.x + 20}px`
      }
    }, [hover, mousePosition])

    // This is how we set the photo iterator back to the clicked image
    const imageCallback = (clickedImage) => {
      setPhotoIndex(clickedImage)
      setIsOpen(true)
    }

    //This is called when the entry animation is done
    const enterCallback = () => {
      // This makes the baja layer visible to begin
      chapters['he-biked-here'].layers.map((layer) => {map.setLayoutProperty(layer, 'visibility', 'visible')})
      history.push(`/he-biked-here`)
      map.flyTo({
        center: [-118.464561, 33.980],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        pitch: 50,
        zoom: 14,
        speed: 0.6
        })
      map.once('moveend', () => {
        setIntroDestroyed(true)
        mapContainer.current.classList.remove('notouch')
      })
    }

    useEffect(() => {
      let vh = isPortraitMobile ? height * 0.01 : width * 0.01;
      if (isMobile) { 
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        scrollToElement(activeChapterName, true)
        if (map) { 
          map.resize()
          map.fitBounds(chapters[activeChapterName].data.bounds, chapters[activeChapterName].data.options) 
        }
      }
    }, [isPortraitMobile])

    // set this up once initially for mobile devices (AGAIN, BOO SAFARI)
    useEffect(() => {
      if (isMobile) {
        document.documentElement.style.setProperty('--vh', `${isPortraitMobile ? height * 0.01 : width * 0.01}px`)
      }
          // Stop keys from functioning if device isn't mobile
    !isMobile && window.addEventListener("keydown", function(e) {
      // space and arrow keys
      if([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
          e.preventDefault();
      }
    }, false);
    }, [])
    
    useEffect(() => {
      const slugIndex = chapterNames.indexOf(slug)
          if (slug) {
            setChapterIndex(slugIndex)
            scrollToElement(chapterNames[slugIndex], false)
          }
   }, [loaded])

   const mapMouseHandler = (e) => {
      setMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
   }

    return(
      <div className='home'>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
        {!introDestroyed && !slug && <IntroModal enterCallback={enterCallback} loaded={loaded} />}
        {creditsOpen && <Credits setCreditsOpen={setCreditsOpen}/>}
          <div className='map notouch' ref={el => mapContainer.current = el} onMouseMove={mapMouseHandler}/>
          <div id='features' ref={scrollRef} onWheel={!isPortraitMobile ? onWheelHandler : undefined} style={{scrollSnapType: isSafari ? 'none' : 'y mandatory' }}>
            {chapterNames.map((chapterName, i) => 
            <Section 
              chapterName={chapterName} 
              key={i} 
              onImageClick={imageCallback} 
              isPortraitMobile={isPortraitMobile} 
              height={height}
              nextChapter={nextChapter} 
              slug={slug}
              setCreditsOpen={setCreditsOpen}
            />
            )}
          </div>
        {isBrowser && <span id='hoverTooltip' ref={el => hoverRef.current = el} className='hidden'></span>}
      </div>
    )
}
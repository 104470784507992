import { ArcLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';

// Import geoJSON map files
import mexico from 'maps/geoJSON/mexico'
import bigbear from 'maps/geoJSON/bigbear'
import love from 'maps/geoJSON/love'
import alc from 'maps/geoJSON/alc'
import firstDate from 'maps/geoJSON/firstDate'
import jonoCommute from 'maps/geoJSON/jono-commute'
import erinCommute from 'maps/geoJSON/erin-commute'
import tony1 from 'maps/geoJSON/tony1'
import tony2 from 'maps/geoJSON/tony2'
import train from 'maps/geoJSON/train'
import cabot from 'maps/geoJSON/cabot'
import boston from 'maps/geoJSON/boston'
import feeltrip1 from 'maps/geoJSON/feeltrip-1'
import feeltrip2 from 'maps/geoJSON/feeltrip-2'
import back2baja from 'maps/geoJSON/back2baja'

// Import color variables
import {p1, p2, p3, p4} from 'style/_variables.scss';

const londonLayer = new MapboxLayer({
  id: 'london',
  type: ArcLayer,
  pickable: true,
  getWidth: 12,
  data: [
      {source: [-106.612898, 35.043430], target: [-0.127758, 51.507353]}
  ],
  getSourcePosition: d => d.source,
  getTargetPosition: d => d.target,
  getSourceColor: d => [255, 255, 255],
  getTargetColor: d => [255, 255, 255],
  getHeight: 0.25,
});

const toTJLayer = new MapboxLayer({
  id: 'toTJ',
  type: ArcLayer,
  pickable: true,
  getWidth: 12,
  data: [
      {source: [-117.160726, 32.710940], target: [-117.123539, 32.530205]}
  ],
  getSourcePosition: d => d.source,
  getTargetPosition: d => d.target,
  getSourceColor: d => [0, 154, 140],
  getTargetColor: d => [0, 96, 87],
  getHeight: 1,
});

const jonoCommuteLayer = {
  'id': 'jono-commute',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': jonoCommute,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "blue",
    "line-width": 6,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p3,
      1,
      p2
    ]
  }
}

const erinCommuteLayer = {
  'id': 'erin-commute',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': erinCommute,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "pink",
    "line-width": 6,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p2,
      1,
      p1
    ]
  }
}

const firstDateLayer = {
  'id': 'firstDate',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': firstDate,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "grey",
    "line-width": 7,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}

const mexicoLayer = {
  'id': 'mexico',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': mexico,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  paint: {
    'line-color': 'red',
    'line-width': 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  },
}

const bigbearLayer = {
  'id': 'big-bear',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': bigbear,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  paint: {
    'line-color': 'red',
    'line-width': 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  },
}

const loveLayer = {
  'id': 'love',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': love,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'pink',
    'line-width': 6,
    // 'line-gradient' must be specified using an expression
    // with the special 'line-progress' property
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p2,
      1,
      p1
    ]
  }
}

const tony1Layer = {
  'id': 'tony1',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': tony1,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'white',
    'line-width': 8
  }
}

const tony2Layer = {
  'id': 'tony2',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': tony2,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'white',
    'line-width': 8
  }
}

const acrossTheUSLayer = new MapboxLayer({
  id: 'acrossTheUS',
  type: ArcLayer,
  pickable: true,
  getWidth: 12,
  data: [
      {source: [-106.396272, 31.801416], target: [-73.9441613,40.678183]}
  ],
  getSourcePosition: d => d.source,
  getTargetPosition: d => d.target,
  getSourceColor: d => [255, 255, 255],
  getTargetColor: d => [255, 255, 255],
  getHeight: 0.25,
});

const alcLayer = {
  'id': 'alc',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': alc,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'red',
    'line-width': 8,
    // 'line-gradient' must be specified using an expression
    // with the special 'line-progress' property
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}

const trainLayer = {
  'id': 'train',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': train,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'red',
    'line-width': 6,
    // 'line-gradient' must be specified using an expression
    // with the special 'line-progress' property
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}

const cabotLayer = {
  'id': 'cabot',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': cabot,
    lineMetrics: true
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    'line-color': 'green',
    'line-width': 6,
    // 'line-gradient' must be specified using an expression
    // with the special 'line-progress' property
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}


const bostonLayer = {
  'id': 'boston',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': boston,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#000",
    "line-width": 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}

const feeltrip1Layer = {
  'id': 'feeltrip-1',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': feeltrip1,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#000",
    "line-width": 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p1,
      1,
      p2
    ]
  }
}

const feeltrip2Layer = {
  'id': 'feeltrip-2',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': feeltrip2,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#000",
    "line-width": 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p2,
      1,
      p3
    ]
  }
}

const back2bajaLayer = {
  'id': 'back2baja',
  'type': 'line',
  'source': {
    'type': 'geojson',
    'data': back2baja,
    lineMetrics: true,
  },
  "layout": {
    "line-join": "round",
    "line-cap": "round"
  },
  "paint": {
    "line-color": "#000",
    "line-width": 8,
    'line-gradient': [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      p3,
      1,
      p2
    ]
  }
}

export default [ 
  jonoCommuteLayer, 
  erinCommuteLayer, 
  firstDateLayer, 
  bigbearLayer,
  mexicoLayer,
  toTJLayer, 
  loveLayer,
  alcLayer, 
  tony1Layer,
  tony2Layer,
  acrossTheUSLayer, 
  trainLayer,
  cabotLayer, 
  bostonLayer,
  feeltrip1Layer, 
  londonLayer, 
  feeltrip2Layer,
  back2bajaLayer
]